// eslint-disable-next-line no-unused-vars
const moduleName = "awsRegistered";

// const AWS = require("aws-sdk");
const AmazonCognitoIdentity = require("amazon-cognito-identity-js");
const CognitoIdentityServiceProvider = require("aws-sdk/clients/cognitoidentityserviceprovider");
const S3 = require("aws-sdk/clients/s3");
const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const AWSRegion = "us-east-2";

import localStoreFunction from "./localStoreFunction.js";

let storeModule;

let setWatchers = false;

function initialState() {
  if (storeModule) {
    if (storeModule.state.refreshTimeout != null) {
      clearTimeout(storeModule.state.refreshTimeout);
    }
  }
  return {
    refreshTimeout: null,
    cognitoUser: null,
    loginInfo: {
      un: null,
      pw: null,
    },
    nextLoginInfo: {
      un: null,
      pw: null,
    },
    callStack: [],
    userData: null,
    gettingUserData: false,
    uncovered: {
      byIndex: {},
      dates: [],
      loaded: false,
    },
    letterLength: -2,
    lastActivity: "",
    credentials: null,
    pendingReset: false,
    // userData: {
    //   username: null,
    //   divisionScheme: null,
    //   readLetter: null,
    //   revealOnScan: null,
    //   scansPerDay: null,
    //   startDate: null,
    // },
    cognitoStore: {
      // We use this in place of localstorage for various cognito things
    },
    IdentityPoolId: {
      production: "us-east-2:b72efbc0-3234-4005-a4b7-7142c0a178b5",
      test: "us-east-2:eac864f6-9dad-46b2-a9b9-0cba43d9651a",
    },
    UserPoolId: {
      production: "us-east-2_qZevwQoYq",
      test: "us-east-2_YrO2gJjlg",
    },
    ClientId: {
      production: "6vb1k1lior1hi0o20tkesq2omj",
      test: "63m7jh8mqf3c436ofnfm0f3m4h",
    },
    BucketKey: {
      production: "pfyt-production",
      test: "pfyt-test",
    },
  };
}

storeModule = {
  namespaced: true,
  state: initialState(),
  mutations: {
    refreshCredentialsTimeout(state, { context, expireTime }) {
      state.refreshTimeout = setTimeout(
        context.dispatch.bind(context, "refreshCredentials", false),
        expireTime
      );
    },
    resetState(state) {
      let oldProps = Object.keys(state);
      let newState = initialState();
      for (let prop of oldProps) {
        state[prop] = newState[prop];
      }
    },
    saveS3(state, tempS3) {
      state.S3 = tempS3;
    },
    updateLogin(state, newLogin) {
      storeModule.mutations.resetState(state);
      state.loginInfo.un = newLogin.un;
      state.loginInfo.pw = newLogin.pw;
    },
    updateNextLogin(state, newLogin) {
      state.nextLoginInfo.un = newLogin.un;
      state.nextLoginInfo.pw = newLogin.pw;
    },
    storeSave(state, obj) {
      for (let key in obj) {
        state.cognitoStore[key] = obj[key];
      }
    },
    storeRemove(state, key) {
      if (Object.prototype.hasOwnProperty.call(state.cognitoStore, key)) {
        delete state.cognitoStore[key];
      }
    },
    storeClear(state) {
      let keys = Object.keys(state.cognitoStore);
      for (let key in keys) {
        delete state.cognitoStore[key];
      }
    },
    saveCognitoUser(state, payload) {
      state.cognitoUser = payload;
    },
    awaitReset(state) {
      state.pendingReset = true;
    },
    updateUserData(state, data) {
      state.userData = data;
    },
    clearUserData(state) {
      state.userData = null;
    },
    saveCredentials(state, data) {
      state.credentials = data;
    },
    updateLastActivity(state, lastActivity) {
      state.lastActivity = lastActivity;
    },
    updateGettingUserData(state, val) {
      state.gettingUserData = val;
    },
    updateUncovered(state, uncovered) {
      let copy = JSON.parse(JSON.stringify(uncovered));
      copy.loaded = true;
      for (let key in state.uncovered) {
        state.uncovered[key] = copy[key];
      }
    },
    loadingUncovered(state) {
      state.uncovered.loaded = "loading";
    },
    updateLetterLength(state, len) {
      state.letterLength = len;
    },
  },
  actions: {
    async updateLastActivity(context, { remoteTime, status = "idle" }) {
      let lastActivity = `${remoteTime}.${
        Math.floor(Math.random() * 9e9) + 1e9
      }_${status}`;
      await context.dispatch("changeAttribute", {
        field: "lastActivity",
        value: lastActivity,
      });
      let { userData } = await context.dispatch("getUserData");
      localStoreFunction(
        "pennyActivity",
        `${context.rootGetters.dateString(
          userData.startDate
        )} - ${context.rootGetters.dateString(userData.endDate)}`,
        lastActivity
      );
      context.commit("updateLastActivity", lastActivity);
    },
    async resetStore(context) {
      await context.dispatch("clearLocalStorage", null, { root: true });
      context.commit("resetState");
    },
    async updateLogin(context, newLogin) {
      context.commit("updateNextLogin", newLogin);
      context.commit("awaitReset");
      await context.dispatch("runRefresh");
    },
    addCall(context, funcName) {
      let unique = "";
      let full = "";
      while (full == "" || context.state.callStack.includes(full)) {
        unique = performance.now();
        full = `${funcName}_${unique}`;
      }
      context.state.callStack.push(full);
      return unique;
    },
    removeCall(context, [funcName, callId]) {
      let index = context.state.callStack.indexOf(`${funcName}_${callId}`);
      if (index > -1) {
        context.state.callStack.splice(index, 1);
      }
      context.dispatch("runRefresh");
    },
    runRefresh(context) {
      if (!context.state.callStack.length && context.state.pendingReset) {
        let toLogin = context.state.loginInfo;
        if (context.state.nextLoginInfo.un && context.state.nextLoginInfo.pw) {
          toLogin = context.state.nextLoginInfo;
        }
        let currentLogin = JSON.parse(JSON.stringify(toLogin));
        context.commit("updateLogin", currentLogin);
      }
    },
    async refreshCredentials(context, isInitial = true) {
      if (isInitial) {
        let credentials = await context.dispatch("getCredentials");
        let expireTime = (credentials.expiration - new Date()) / 2;
        context.commit("refreshCredentialsTimeout", { context, expireTime });
      } else {
        context.commit("awaitReset");
        context.dispatch("runRefresh");
      }
    },
    async getCognitoUser(context) {
      if (context.state.cognitoUser == null) {
        if (
          context.state.loginInfo.un == null ||
          context.state.loginInfo.pw == null
        ) {
          throw "Missing login information.";
        }
        let authenticationDetails =
          new AmazonCognitoIdentity.AuthenticationDetails({
            Username: context.state.loginInfo.un,
            Password: context.state.loginInfo.pw,
          });

        let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: context.state.loginInfo.un,
          Pool: new AmazonCognitoIdentity.CognitoUserPool({
            UserPoolId: context.getters["UserPoolId"],
            ClientId: context.getters["ClientId"],
            Storage: context.getters["Storage"](context),
          }),
          Storage: context.getters["Storage"](context),
        });
        await new Promise((resolve, reject) => {
          cognitoUser.authenticateUser(authenticationDetails, {
            async onSuccess() {
              context.commit("saveCognitoUser", cognitoUser);
              await context.dispatch("refreshCredentials");
              resolve();
            },
            onFailure(err) {
              // console.log("onFailure", { err });
              reject(err);
            },
          });
        });
      }
      return context.state.cognitoUser;
    },
    async getCognitoSession(context) {
      let cognitoUser = await context.dispatch("getCognitoUser");
      return await new Promise((resolve, reject) => {
        cognitoUser.getSession((error, session) => {
          if (error) {
            reject(error);
          } else {
            resolve(session);
          }
        });
      });
    },
    async getCredentials(context) {
      if (context.state.credentials != null) {
        return context.state.credentials;
      }
      let cognitoSession = await context.dispatch("getCognitoSession");
      let credentials = fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: AWSRegion }),
        identityPoolId: context.getters["IdentityPoolId"],
        logins: {
          // Change the key below according to the specific region your user pool is in.
          [`cognito-idp.${AWSRegion}.amazonaws.com/${context.getters["UserPoolId"]}`]:
            cognitoSession.getIdToken().getJwtToken(),
        },
        cache: context.getters.Storage(context),
      });
      let realCredentials = await credentials();
      context.commit("saveCredentials", realCredentials);
      return context.state.credentials;
    },
    async getUserData(context, force = false) {
      context.commit("updateGettingUserData", true);
      if (context.state.userData != null) {
        if (
          !(
            new Date().getTime() - context.state.userData.lastUpdated >
              5 * 60 * 1000 || force
          )
        ) {
          // it hasn't been more than 5 minutes AND we're not running a 'force' operation
          return { userData: context.state.userData, newData: false };
        }
      }
      let cognitoUser = await context.dispatch("getCognitoUser");
      let [
        { UserAttributes: attrs },
        {
          accessToken: {
            payload: { "cognito:groups": cognitoGroups },
          },
        },
      ] = await Promise.all([
        new Promise((resolve, reject) => {
          cognitoUser.getUserData(
            (err, data) => {
              if (err) {
                reject(err);
                return;
              }
              resolve(data);
              return;
            },
            { bypassCache: true }
          );
        }),
        new Promise((resolve, reject) => {
          cognitoUser.getSession((err, session) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(session);
            return;
          });
        }),
      ]);
      cognitoGroups = cognitoGroups || [];

      let attrMap = {
        sub: "username",
        "custom:readLetter": "readLetter",
        "custom:revealOnScan": "revealOnScan",
        "custom:scansPerDay": "scansPerDay",
        "custom:startDate": "startDate",
        "custom:endDate": "endDate",
        "custom:lastActivity": "lastActivity",
      };
      let transformMap = {
        sub: (x) => x,
        "custom:readLetter": (x) => !!Number(x),
        "custom:revealOnScan": (x) => !!Number(x),
        "custom:scansPerDay": (x) => Number(x),
        "custom:startDate": (x) => new Date(Number(x)),
        "custom:endDate": (x) => new Date(Number(x)),
        "custom:lastActivity": (x) => x,
      };
      let outputMap = {};
      let forcedUpdates = [];
      for (let tempAttr in attrMap) {
        let found = false;
        for (let pulledAttr of attrs) {
          if (pulledAttr.Name == tempAttr) {
            found = true;
            break;
          }
        }
        if (!found) {
          forcedUpdates.push(tempAttr);
          attrs.push({
            Name: tempAttr,
            Value: "",
          });
        }
      }
      for (let attrItem of attrs) {
        let attr = attrItem.Name;
        if (!Object.prototype.hasOwnProperty.call(attrMap, attr)) {
          attr = "custom:" + attr;
        }
        if (Object.prototype.hasOwnProperty.call(attrMap, attr)) {
          outputMap[attrMap[attr]] = transformMap[attr](attrItem.Value);
        }
      }

      outputMap["printLabels"] = false; // "waiting", or false?
      if (cognitoGroups.includes("donePrinting")) {
        outputMap["printLabels"] = "done";
      } else if (cognitoGroups.includes("needsPrinting")) {
        outputMap["printLabels"] = "waiting";
      }

      outputMap.lastUpdated = new Date().getTime();
      context.commit("updateUserData", outputMap);

      if (context.state.lastActivity === "") {
        let lastActivity = localStoreFunction(
          "pennyActivity",
          `${context.rootGetters.dateString(
            outputMap.startDate
          )} - ${context.rootGetters.dateString(outputMap.endDate)}`
        );
        if (lastActivity) {
          context.commit("updateLastActivity", lastActivity);
        }
      }

      if (forcedUpdates.includes("custom:lastActivity")) {
        let remoteTime = await context.dispatch("getTime", null, {
          root: true,
        });
        await context.dispatch("updateLastActivity", { remoteTime });
      }

      context.commit("updateGettingUserData", false);
      return { userData: context.state.userData, newData: true };
    },
    async getS3Connection(context) {
      let credentials = await context.dispatch("getCredentials");
      let tempS3 = new S3({
        region: AWSRegion,
        credentials,
        params: {
          Bucket: context.getters["BucketKey"],
        },
      });
      return tempS3;
    },
    async saveS3Files(context, payload) {
      let S3 = await context.dispatch("getS3Connection");
      let filePromises = [];
      for (let file of payload.files) {
        filePromises.push(
          S3.putObject({
            ContentType: `${file.type}; charset=utf-8`,
            Key: `${
              (await context.dispatch("getUserData")).userData.username
            }/${file.name}.${file.ext}`,
            Body: file.type == "json" ? JSON.stringify(file.data) : file.data,
          }).promise()
        );
      }
      return Promise.all(filePromises);
    },
    async listS3Files(context) {
      let S3 = await context.dispatch("getS3Connection");
      return await S3.listObjects({
        Prefix: `${(await context.dispatch("getUserData")).userData.username}/`,
      }).promise();
    },
    async getLetter(context) {
      context.commit("updateLetterLength", -1);
      let userName = (await context.dispatch("getUserData")).userData.username;
      let localLetter = context.rootGetters.localStore(userName, "letter");
      if (!localLetter) {
        // not quite an else here since we might set it false above
        let userName = (await context.dispatch("getUserData")).userData
          .username;
        let S3 = await context.dispatch("getS3Connection");
        let file = await S3.getObject({
          Key: `${userName}/letter.txt`,
        }).promise();
        // update localLetter
        localLetter = {
          data: file.Body.toString("utf-8"),
          LastModified: file.LastModified,
        };
        context.rootGetters.localStore(userName, "letter", localLetter);
      }

      let letterData = context.rootGetters["encryption/decrypt"](
        localLetter.data,
        userName.replace(/[^A-Za-z0-9]/gi, "")
      );

      context.commit("updateLetterLength", letterData.length);
      return letterData;
    },
    async getUncovered(context, { force = false, need = false }) {
      // if (context.state.cognitoCredentials == null) {
      //   await context.dispatch("getCredentials");
      // }
      if (context.state.uncovered.loaded) {
        if (!need && !force) {
          return { uncovered: context.state.uncovered, newData: false };
        }
      }
      context.commit("loadingUncovered");
      let { userData, newData } = await context.dispatch("getUserData", force);
      let userName = userData.username;
      let localDiscovered = context.rootGetters.localStore(
        userName,
        "uncovered"
      );
      let outOfDate = userData.lastActivity != context.state.lastActivity;
      // console.log("OUT OF DATE IS: ", outOfDate);
      let registerActivity = false;
      let remoteTime = null;
      if (outOfDate) {
        if (!newData) {
          let { userData: ud, newData: nd } = await context.dispatch(
            "getUserData",
            true
          );
          userData = ud;
          newData = nd;
        }
        registerActivity = true;
        if (userData.lastActivity.split("_")[1] == "busy") {
          registerActivity = false;
          // CRAP that's bad it means that the file is being updated.
          //... actually, it MIGHT be an old error, so this is where we need to compare timestamps.
          let time = Number(userData.lastActivity.split(".")[0]);
          remoteTime = Number(
            await context.dispatch("getTime", null, { root: true })
          );
          if (remoteTime - time > 5 * 60 * 1000) {
            // it's been more than 5 minutes, so that's a faulty/bad "busy" indicator.
            registerActivity = true;
          } else {
            // we'll try waiting for like 5 seconds to give it time to finish, but... not hopeful.
            await new Promise((resolve) => setTimeout(resolve, 5000));
            let { userData: tempUserData, newData: tempNewData } =
              await context.dispatch("getUserData", true);
            userData = tempUserData;
            newData = tempNewData;
            if (userData.lastActivity.split("_")[1] == "idle") {
              // great, it must have finished.  Hurray!
              registerActivity = true;
            } else {
              // bad, we want to mark an error and give up.
              await context.dispatch(
                "addError",
                "There was a network conflict error - please try again in 5 minutes."
              );
              return;
            }
          }
        }
      }
      if (registerActivity) {
        if (remoteTime === null) {
          remoteTime = Number(
            await context.dispatch("getTime", null, { root: true })
          );
        }
        await context.dispatch("updateLastActivity", { remoteTime });
      }
      if (outOfDate && localDiscovered) {
        let fileList = await context.dispatch("listS3Files");
        let found = false;
        let match = false;
        if (Object.prototype.hasOwnProperty.call(fileList, "Contents")) {
          for (let file of fileList.Contents) {
            if (file.Key == `${userName}/uncovered.txt`) {
              found = true;
              if (
                new Date(localDiscovered.LastModified).getTime() ==
                new Date(file.LastModified).getTime()
              ) {
                match = true;
              }
              break;
            }
          }
        }
        // check to see if the last modified date matches.  If not, we need to pull it.
        // To make sure we pull it, set localLetter to null
        if (found && !match) {
          localDiscovered = null;
        }
      }
      if (!localDiscovered) {
        // not quite an else here since we might set it false above

        let S3 = await context.dispatch("getS3Connection");
        let file = await S3.getObject({
          Key: `${userName}/uncovered.txt`,
        }).promise();
        let strData = file.Body.toString("utf-8");
        let arrData = strData.split("\n").filter((a) => a != "");
        let byIndex = {};
        let dates = [];
        for (let item of arrData) {
          let [index, date] = item.split("-");
          byIndex[index] = Number(date);
          dates.push(byIndex[index]);
        }
        dates.sort((a, b) => a - b);

        let uncoveredData = {
          byIndex,
          dates,
        };

        // update localLetter
        localDiscovered = {
          data: uncoveredData,
          LastModified: file.LastModified,
        };
        context.rootGetters.localStore(userName, "uncovered", localDiscovered);
      }

      context.commit("updateUncovered", localDiscovered.data);
      return { uncovered: localDiscovered.data, newData };
    },
    async canUncover(context, { userData, uncovered, uncoverId }) {
      // need to look at current date and allowed uncovers/day, etc, see if we can.
      let totalToDo = 0;
      if (context.state.letterLength < 0) {
        totalToDo = (await context.dispatch("getLetter")).length;
      } else {
        totalToDo = context.state.letterLength;
      }
      let thisDate = new Date();
      thisDate = new Date(
        thisDate.getFullYear(),
        thisDate.getMonth(),
        thisDate.getDate()
      );
      let startDate = userData.startDate;
      let timeBetween = thisDate.getTime() - startDate.getTime();
      let daysBetween = Math.round(timeBetween / (1000 * 60 * 60 * 24)) + 1;
      let allowedSoFar = Math.min(
        userData.scansPerDay * daysBetween,
        totalToDo
      );
      let totalSoFar = Object.keys(uncovered.byIndex).length;
      let withinLimit = totalSoFar < allowedSoFar;
      return {
        canUncover: withinLimit && !!uncoverId,
        withinLimit,
        daysBetween,
        totalWords: totalToDo,
      };
    },
    async uncover(context, uncoverId) {
      uncoverId = Number(uncoverId);

      let { userData, newData } = await context.dispatch("getUserData");
      let { uncovered, newData: nd } = await context.dispatch("getUncovered", {
        need: true,
      });
      newData = newData || nd;

      let isUncovered = Object.prototype.hasOwnProperty.call(
        uncovered.byIndex,
        uncoverId
      );

      let totalWords = 0;
      if (!isUncovered) {
        let {
          canUncover,
          withinLimit,
          daysBetween,
          totalWords: tw,
        } = await context.dispatch("canUncover", {
          userData,
          uncovered,
          uncoverId,
        });
        totalWords = tw;

        if ((canUncover || !isUncovered) && !newData) {
          // we need to force new data to see if we're really good.
          let { uncovered: u } = await context.dispatch("getUncovered", {
            force: true,
            need: true,
          });
          uncovered = u;
          let {
            canUncover: cu,
            withinLimit: wl,
            daysBetween: db,
          } = await context.dispatch("canUncover", {
            userData,
            uncovered,
            uncoverId,
          });
          canUncover = cu;
          withinLimit = wl;
          daysBetween = db;
          newData = true;
          isUncovered = Object.prototype.hasOwnProperty.call(
            uncovered.byIndex,
            uncoverId
          );
        }
        if (!canUncover && !isUncovered) {
          let returnMessage =
            "That QR Code doesn't appear to belong to this letter.";
          if (daysBetween < 1) {
            returnMessage =
              "You must wait until the start date to begin scanning QR codes.";
          } else if (!withinLimit) {
            returnMessage =
              "You've reached the max number of scans allowed for today.";
          }
          return {
            status: "error",
            message: returnMessage,
          };
        }
      }

      let lastWord =
        Object.keys(uncovered.byIndex).length == totalWords - 1 && !isUncovered;
      let returnObj = {
        status: isUncovered ? "warning" : "success",
        time: new Date().getTime(),
        last: lastWord,
      };
      if (!isUncovered) {
        // store it
        uncovered.byIndex[uncoverId] = returnObj.time;
        uncovered.dates.push(returnObj.time);
        uncovered.dates.sort((a, b) => a - b);
        await context.dispatch("saveUncovered", uncovered);
      }
      return returnObj;
    },
    async saveUncovered(context, currentlyUncovered) {
      let outArr = [];
      for (let key in currentlyUncovered.byIndex) {
        outArr.push(key + "-" + currentlyUncovered.byIndex[key]);
      }
      let dataStr = outArr.join("\n");
      let remoteTime = Number(
        await context.dispatch("getTime", null, { root: true })
      );
      await context.dispatch("updateLastActivity", {
        remoteTime,
        status: "busy",
      });
      // let response = await context.dispatch("saveS3Files", {
      await context.dispatch("saveS3Files", {
        files: [
          {
            name: "uncovered",
            ext: "txt",
            type: "text/plain",
            data: dataStr,
          },
        ],
      });
      await context.dispatch("updateLastActivity", { remoteTime });
      //now we need to update the local store with the 'last modified' that comes back so we don't have to pull the data again.

      /*
      //FUN FACT: We don't ACTUALLY have to do that with the new lockstep system, which is neat.  Basically
      //It'll happen automatically if we ever end up out of date, and this way we don't have to run an extra API request.
      let fileList = await context.dispatch("listS3Files");
      let updatedDate = null;
      */
      let userName = (await context.dispatch("getUserData")).userData.username;
      /*
      if (Object.prototype.hasOwnProperty.call(fileList, "Contents")) {
        for (let file of fileList.Contents) {
          if (file.Key == `${userName}/uncovered.txt`) {
            updatedDate = new Date(file.LastModified);
            break;
          }
        }
      }

      */
      // if (updatedDate) {
      let localDiscovered = {
        data: currentlyUncovered,
        // LastModified: updatedDate,
        LastModified: new Date(),
      };
      context.commit("updateUncovered", currentlyUncovered);
      context.rootGetters.localStore(userName, "uncovered", localDiscovered);
      // }
    },
    async changeAttribute(context, payload) {
      // TODO make sure payload is one of the allowed changes

      let CISP = new CognitoIdentityServiceProvider({
        region: AWSRegion,
        apiVersion: "2016-04-18",
      });
      await CISP.updateUserAttributes({
        AccessToken: (await context.dispatch("getCognitoSession"))
          .getAccessToken()
          .getJwtToken(),
        UserAttributes: [
          /* required */
          {
            Name: `custom:${payload.field}`,
            Value: "" + payload.value,
          },
        ],
      }).promise();
      await context.dispatch("getUserData", true);
    },
  },
  getters: {
    userData(state) {
      if (state.userData === null) {
        return state.gettingUserData;
      } else {
        return state.userData;
      }
    },
    uncovered(state) {
      return state.uncovered;
    },
    letterLength(state) {
      return state.letterLength;
    },
    IdentityPoolId(state, getters, rootState, rootGetters) {
      return state.IdentityPoolId[rootGetters["environment"]];
    },
    BucketKey(state, getters, rootState, rootGetters) {
      return state.BucketKey[rootGetters["environment"]];
    },
    UserPoolId(state, getters, rootState, rootGetters) {
      return state.UserPoolId[rootGetters["environment"]];
    },
    ClientId(state, getters, rootState, rootGetters) {
      return state.ClientId[rootGetters["environment"]];
    },
    Storage: () => (context) => {
      return {
        setItem(key, value) {
          context.commit("storeSave", { [key]: value });
        },
        getItem(key) {
          return Object.prototype.hasOwnProperty.call(
            context.state.cognitoStore,
            key
          )
            ? context.state.cognitoStore[key]
            : null;
        },
        removeItem(key) {
          context.commit("storeRemove", key);
        },
        clear() {
          context.commit("storeClear");
        },
      };
    },
  },
};

/*
The flow for this module is weird.  Essentially, we have the un/pw we need in perpetuity, but we don't want to deal
with the hassle of refreshing the tokens.  So instead, when we initially establish the user connection, we set a
timeout to half the expiration length of the token.  When that timeout hits, we set a flag that says "we need to
reset the store."  Each time an action is called, we store it in a call stack.  When the action finishes, we pop
it from the stack.  When the stack is emptied AND the reset flag is set, we reset everything in the store except
the un/pw.  To manage the action-tracking without writing code for every single action, we have this block of
code below: it captures each action in the store and redefines it: it saves the old function, and returns an async
promise: the promise pushes the call to the stack, awaits the original old function, pops the call from the stack,
and then resolves/rejects based on the execution results from the original/old function.
*/

if (!setWatchers) {
  let allActions = Object.keys(storeModule.actions);
  let removeFrom = ["addCall", "removeCall", "runRefresh", "updateLogin"];
  for (let item of removeFrom) {
    if (allActions.includes(item)) {
      allActions.splice(allActions.indexOf(item), 1);
    }
  }
  for (let action of allActions) {
    let func = storeModule.actions[action];
    let context;
    let callId;
    let newFunc = (...args) => {
      context = args[0];
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        callId = await context.dispatch("addCall", action);
        let result;
        let toThrow;
        try {
          result = await func(...args);
        } catch (err) {
          toThrow = err;
        }
        await context.dispatch("removeCall", [action, callId]);
        if (toThrow) {
          reject(toThrow);
        } else {
          resolve(result);
        }
      });
    };
    storeModule.actions[action] = newFunc;
  }
  setWatchers = true;
}

export default storeModule;
